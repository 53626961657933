module.exports = [{
      plugin: require('/Users/urbanmarovt/Documents/Work/Banostudios/OdDo/LandingPage/node_modules/gatsby-plugin-drift/gatsby-browser.js'),
      options: {"plugins":[],"appId":"k5hxn643m3rf"},
    },{
      plugin: require('/Users/urbanmarovt/Documents/Work/Banostudios/OdDo/LandingPage/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-137872687-1"},
    },{
      plugin: require('/Users/urbanmarovt/Documents/Work/Banostudios/OdDo/LandingPage/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
